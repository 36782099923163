import type { ResultsPageAnalyticsParams } from '@/models/analyticsParams/ResultsPageAnalyticsParams';
import type { ProfilePageAnalyticsParams } from '@/models/analyticsParams/ProfilePageAnalyticsParams';
import React, { createContext, ReactNode, useMemo, useState } from 'react';

export type AnalyticsParamsContextProps = {
  resultsPageAnalyticsParams: ResultsPageAnalyticsParams;
  setResultsPageAnalyticsParams: (params: ResultsPageAnalyticsParams) => void;
  profilePageAnalyticsParams: ProfilePageAnalyticsParams;
  setProfilePageAnalyticsParams: (params: ProfilePageAnalyticsParams) => void;
};

export const AnalyticsParamsContext = createContext<AnalyticsParamsContextProps>({
  resultsPageAnalyticsParams: {} as ResultsPageAnalyticsParams,
  setResultsPageAnalyticsParams: () => {},
  profilePageAnalyticsParams: {} as ProfilePageAnalyticsParams,
  setProfilePageAnalyticsParams: () => {}
});

type Props = {
  children: ReactNode;
};

/**
 * Here we store the analytics specific properties that
 * might be required while switching between different pages
 *
 * !!! Please don't exhaust this context with unnecessary     !!!
 * !!! properties that can be directly accessed from the page !!!
 */
export default function AnalyticsParamsProvider({ children }: Props) {
  const [resultsPageAnalyticsParams, setResultsPageAnalyticsParams] = useState<ResultsPageAnalyticsParams>({});
  const [profilePageAnalyticsParams, setProfilePageAnalyticsParams] = useState<ProfilePageAnalyticsParams>({});

  const value = useMemo(
    () => ({
      resultsPageAnalyticsParams,
      setResultsPageAnalyticsParams,
      profilePageAnalyticsParams,
      setProfilePageAnalyticsParams
    }),
    [
      resultsPageAnalyticsParams,
      setResultsPageAnalyticsParams,
      profilePageAnalyticsParams,
      setProfilePageAnalyticsParams
    ]
  );

  return <AnalyticsParamsContext.Provider value={value}>{children}</AnalyticsParamsContext.Provider>;
}
